<p-toast />
<div class="w-full">
  <form [formGroup]="personalDataForm" (ngSubmit)="updateUserData()" class="w-full">
    <div class="grid">
      <!-- Apellido -->
      <div class="col-12 md:col-4">
        <label class="labelTitle" for="apellido">Apellido*</label>
        <input
          id="apellido"
          placeholder="Apellido"
          type="text"
          pInputText
          formControlName="last_name"
          [ngClass]="{'ng-invalid ng-dirty': hasErrors('apellido', 'required') || hasErrors('apellido', 'maxlength')}"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('apellido', 'required')">
          Este campo es obligatorio
        </div>
        <div class="text-danger p-error" *ngIf="hasErrors('apellido', 'maxlength')">
          Este campo no puede poseer más de 50 caracteres
        </div>
      </div>

      <!-- Nombre -->
      <div class="col-12 md:col-4">
        <label class="labelTitle" for="nombre">Nombre*</label>
        <input
          id="nombre"
          placeholder="Nombre"
          type="text"
          pInputText
          formControlName="name"
          [ngClass]="{'ng-invalid ng-dirty': hasErrors('nombre', 'required') || hasErrors('nombre', 'maxlength')}"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('nombre', 'required')">
          Este campo es obligatorio
        </div>
        <div class="text-danger p-error" *ngIf="hasErrors('nombre', 'maxlength')">
          Este campo no puede poseer más de 50 caracteres
        </div>
      </div>

      <!-- CUIL -->
      <div class="col-12 md:col-4">
        <label class="labelTitle" for="cuil">CUIL*</label>
        <input
          id="cuil"
          placeholder="CUIL"
          type="text"
          pInputText
          formControlName="cuil"
          [ngClass]="{'ng-invalid ng-dirty': hasErrors('cuil', 'required') || hasErrors('cuil', 'pattern')}"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('cuit', 'required')">
          Este campo es obligatorio
        </div>
        <div class="text-danger p-error" *ngIf="hasErrors('cuit', 'pattern')">
          Formato de CUIT inválido
        </div>
      </div>

      <ng-container *ngIf="this.rolUser !== 'Administrador' && this.rolUser !== 'Conciliador' && this.rolUser !== 'Miembro de la comisión'">
        <!-- Fecha de Nacimiento -->
        <div class="col-12 md:col-4">
          <label class="labelTitle" for="fechaNacimiento">Fecha de Nacimiento*</label>
          <p-calendar
            formControlName="birth_date"
            [iconDisplay]="'input'"
            [showIcon]="true"
            inputId="icondisplay"
            slotChar="dd/mm/yyyy"
            dateFormat="dd/mm/yy"
            [ngClass]="{'ng-invalid ng-dirty': hasErrors('fechaNacimiento', 'required')}"
          ></p-calendar>
          <div class="text-danger p-error" *ngIf="hasErrors('fechaNacimiento', 'required')">
            Este campo es obligatorio
          </div>
        </div>

        <!-- Sexo -->
        <div class="col-12 md:col-4">
          <label class="labelTitle" for="sexo">Sexo*</label>
          <p-dropdown
            id="sexo"
            [options]="sexOptions"
            formControlName="gender"
            placeholder="Seleccione Sexo"
            [ngClass]="{'ng-invalid ng-dirty': hasErrors('sexo', 'required')}"
          ></p-dropdown>
          <div class="text-danger p-error" *ngIf="hasErrors('sexo', 'required')">
            Este campo es obligatorio
          </div>
        </div>

        <!-- Estado Civil -->
        <div class="col-12 md:col-4">
          <label class="labelTitle" for="estadoCivil">Estado Civil*</label>
          <p-dropdown
            id="estadoCivil"
            [options]="civilStatusOptions"
            optionLabel="label"
            optionValue="value"
            formControlName="civil_status"
            placeholder="Seleccione Estado Civil"
            [ngClass]="{'ng-invalid ng-dirty': hasErrors('estadoCivil', 'required')}"
          ></p-dropdown>
          <div class="text-danger p-error" *ngIf="hasErrors('estadoCivil', 'required')">
            Este campo es obligatorio
          </div>
        </div>
      </ng-container>

      <!-- Correo Electrónico -->
      <div class="col-12 md:col-4">
        <label class="labelTitle" for="correo">Correo Electrónico*</label>
        <input
          id="correo"
          placeholder="Correo Electrónico"
          type="email"
          pInputText
          formControlName="email"
          [ngClass]="{'ng-invalid ng-dirty': hasErrors('correo', 'required') || hasErrors('correo', 'email')}"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('correo', 'required')">
          Este campo es obligatorio
        </div>
        <div class="text-danger p-error" *ngIf="hasErrors('correo', 'email')">
          Formato de correo inválido
        </div>
      </div>

      <!-- Teléfono -->
      <div class="col-12 md:col-4">
        <label class="labelTitle" for="telefono">Teléfono*</label>
        <input
          id="telefono"
          placeholder="Teléfono"
          type="tel"
          pInputText
          formControlName="phone"
          [ngClass]="{'ng-invalid ng-dirty': hasErrors('telefono', 'required') || hasErrors('telefono', 'minlength') || hasErrors('telefono', 'maxlength')}"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('telefono', 'required')">
          Este campo es obligatorio
        </div>
        <div class="text-danger p-error" *ngIf="hasErrors('telefono', 'minlength')">
          El teléfono debe tener al menos 7 caracteres
        </div>
        <div class="text-danger p-error" *ngIf="hasErrors('telefono', 'maxlength')">
          El teléfono no puede superar 15 caracteres
        </div>
      </div>

      <!-- Dirección -->
      <div class="col-12 md:col-4">
        <label class="labelTitle" for="calle">Calle*</label>
        <input
          id="calle"
          placeholder="Calle"
          type="text"
          pInputText
          formControlName="street"
          [ngClass]="{'ng-invalid ng-dirty': hasErrors('calle', 'required') || hasErrors('calle', 'maxlength')}"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('calle', 'required')">
          Este campo es obligatorio
        </div>
      </div>

      <!-- Número -->
      <div class="col-12 md:col-2">
        <label class="labelTitle" for="numero">Número*</label>
        <input
          id="numero"
          placeholder="Número"
          type="text"
          pInputText
          formControlName="number"
          [ngClass]="{'ng-invalid ng-dirty': hasErrors('numero', 'required') || hasErrors('numero', 'pattern')}"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('numero', 'required')">
          Este campo es obligatorio
        </div>
        <div class="text-danger p-error" *ngIf="hasErrors('numero', 'pattern')">
          Solo se permiten números
        </div>
      </div>

      <!-- Piso/Depto -->
      <div class="col-12 md:col-2">
        <label class="labelTitle" for="pisoDepto">Piso/Depto</label>
        <input
          id="pisoDepto"
          placeholder="Piso/Depto"
          type="text"
          pInputText
          formControlName="floor_apartment"
          [ngClass]="{'ng-invalid ng-dirty': hasErrors('pisoDepto', 'maxlength')}"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('pisoDepto', 'maxlength')">
          Máximo 10 caracteres
        </div>
      </div>

      <!-- Provincia -->
      <div class="col-12 md:col-4">
        <label class="labelTitle" for="provincia">Provincia*</label>
        <p-dropdown
          id="provincia"
          [options]="provinces"
          optionLabel="name"
          optionValue="id"
          formControlName="province"
          placeholder="Seleccione Provincia"
          [ngClass]="{'ng-invalid ng-dirty': hasErrors('provincia', 'required')}"
        ></p-dropdown>
        <div class="text-danger p-error" *ngIf="hasErrors('provincia', 'required')">
          Este campo es obligatorio
        </div>
      </div>

      <!-- Localidad -->
      <div class="col-12 md:col-4">
        <label class="labelTitle">Localidad<span class="span">*</span></label>
        <p-dropdown
          [options]="localities"
          optionLabel="name"
          optionValue="name"
          [showClear]="false"
          placeholder="Seleccione su localidad"
          formControlName="city"
          [ngClass]="{'ng-invalid ng-dirty': hasErrors('claimetCity', 'required')}"
        ></p-dropdown>
        <div class="text-danger p-error" *ngIf="hasErrors('localidad', 'required')">
          Este campo es obligatorio
        </div>
      </div>

      <!-- Código Postal -->
      <div class="col-12 md:col-4">
        <label class="labelTitle" for="codigoPostal">Código Postal*</label>
        <input
          id="codigoPostal"
          placeholder="Código Postal"
          type="text"
          pInputText
          formControlName="postal_code"
          [ngClass]="{'ng-invalid ng-dirty': hasErrors('codigoPostal', 'required') || hasErrors('codigoPostal', 'minlength') || hasErrors('codigoPostal', 'maxlength')}"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('codigoPostal', 'required')">
          Este campo es obligatorio
        </div>
        <div class="text-danger p-error" *ngIf="hasErrors('codigoPostal', 'minlength')">
          Mínimo 4 caracteres
        </div>
        <div class="text-danger p-error" *ngIf="hasErrors('codigoPostal', 'maxlength')">
          Máximo 10 caracteres
        </div>
      </div>

      <!-- Botones -->
      <div class="grid w-full flex justify-content-end mt-2">
        <div class="col-6 md:col-2 lg:col-2 xl:col-2">
          <p-button (click)="cancel()" label="Cancelar" class="btn-cancelar sm:text-xs"></p-button>
        </div>
        <div class="col-6 md:col-2 lg:col-2 xl:col-2 pr-0">
          <p-button label="Guardar Cambios" class="btn-siguiente" type="submit" [disabled]="!isFormModified"></p-button>
        </div>
      </div>
    </div>
  </form>
</div>
